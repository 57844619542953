import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Button from '../../../../../Components/Button'
import Spinner from '../../../../../Components/Spinner'
import { toastError, toastSuccess } from '../../../../../Components/Utils/Utils'
import { useP } from '../../../../../services/i18n'
import { SUBMIT_BON_COMMANDE } from '../../../queries'
import { ModalPrompt } from '../../../../../Components/ModalDialog/ModalPrompt'
import * as Sentry from "@sentry/react";

type ActionBonCommandePromptType = {
  livraisonId: number,
  action: string,
  keyValue: string,
  label: string,
  message: string|JSX.Element,
  inputLabel: string,
  inputType: string,
  inputPlaceholder?: string,
  selectDatas?: any[],
  prestationId: number,
  checkValue?: (value: any) => boolean
  disabled?: boolean
}

export default function ActionBonCommandePrompt({ disabled=false, livraisonId, action, keyValue, label, message, inputLabel, inputType,inputPlaceholder, selectDatas = [], prestationId, checkValue = () => true }: ActionBonCommandePromptType) {

  const p = useP();

  const [submitBonCommande, { loading }] = useMutation(SUBMIT_BON_COMMANDE);

  const submitAction = (value) => {
    submitBonCommande({
      variables: {
        bonCommandeInput: {
          action,
          livraisonId: livraisonId,
          values: [{
            key: keyValue,
            value
          }],
          prestationId: prestationId,
        }
      }
    }).then(({ data }) => {

      if (data.submitBonCommande.success) {
        toastSuccess(p.t('prestations.gsm.actions.submitBonCommandeSuccessMessage'));
      } else {

        const codeErreur = data?.submitBonCommande?.codeErreur;
        const tradErreur = `prestations.gsm.errors.${codeErreur}`
        const erreurMessage = p.has(tradErreur) ? p.t(tradErreur) : `${p.t('error.defaultMessage')} (Err: ${codeErreur})`

        console.error(data.submitBonCommande.message)
        Sentry.captureException(data.submitBonCommande.message);
        toastError(erreurMessage);
      }
    })
      .catch((error) => {
        Sentry.captureException(error);
        toastError(p.t('error.defaultMessage'));
      });
  }

  // Modale de confirmation
  const [modaleIsOpen, setModaleIsOpen] = useState(false)

  return <>
    <div className=''>
      <Button
        disabled={loading || disabled}
        color='primary'
        size='small'
        onClick={() => setModaleIsOpen(true)}>
        {loading ? <Spinner /> : label}
      </Button>
    </div>

    <ModalPrompt
      inputProps={{
        type: inputType,
        label: inputLabel,
        placeholder: inputPlaceholder
      }}
      title={message}
      isOpen={modaleIsOpen}
      onClose={(action, value) => {
        if (action) {
          if (!checkValue(value))
            return;
          submitAction(value);
        }
        setModaleIsOpen(false)
      }}
      selectDatas={selectDatas}
    />
  </>
}